import { Loader } from '@googlemaps/js-api-loader'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { Search } from 'react-feather'
import { CapsuleInputState } from 'utils'

import { ThemedTextField } from '../../common/styledComponents/ThemedTextField'
import { useContentfulTheme } from '../../hooks'

interface PlacesAutocompleteProps {
  onChange: (place: Place) => void
  initValue: string
  disabled: boolean
}

export interface Place {
  address: string
  phoneNumber: string
  name: string
}

const GooglePlacesAutocomplete = ({ onChange, initValue, disabled }: PlacesAutocompleteProps): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState<string>(initValue)
  const [inputState, setInputState] = useState<CapsuleInputState>('default')
  const { theme } = useContentfulTheme()

  useEffect(() => {
    const options = {
      componentRestrictions: { country: 'us' },
      fields: ['formatted_address', 'formatted_phone_number', 'name'],
      types: ['pharmacy'],
    }

    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_TOKEN as string,
      libraries: ['places'],
      version: 'weekly',
    })

    loader.load().then((google) => {
      if (inputRef.current) {
        const autocomplete = new google.maps.places.Autocomplete(inputRef.current, options)

        autocomplete.addListener('place_changed', () => {
          const placeResult = autocomplete.getPlace()
          setValue(`${placeResult.name} - ${placeResult.formatted_address}`)
          setInputState('complete')
          onChange({
            address: placeResult.formatted_address || 'unknown',
            phoneNumber: placeResult.formatted_phone_number || 'unknown',
            name: placeResult.name || 'unknown',
          })
        })
      }
    })
  }, [inputRef, onChange, value])

  return (
    <div>
      <ThemedTextField
        id='google-places'
        ref={inputRef}
        label='Pharmacy name or address *'
        required
        placeholder='E.g. CVS, Walgreens'
        value={value}
        state={inputState}
        disabled={disabled}
        onChange={(e) => {
          e.preventDefault()
          if (!e.target.value) {
            setInputState('error')
          }
          setValue(e.target.value)
        }}
        startAdornment={Search}
        css={{
          paddingLeft: '$2xl',
        }}
        vpTheme={theme}
      />
    </div>
  )
}
export default GooglePlacesAutocomplete
