import { OrderDetails, OrderParams, RetrieveOrdersResponse, sharedConstants, SubmitOrderResponse } from '@vpharm-platform/shared'
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'

import { axiosAuth } from '../httpClient'

export interface OrderService {
  submitOrder(customerToken: string, patientToken: string, orderData: OrderParams): Promise<SubmitOrderResponse>
  getOrders(params: GetOrdersParams): Promise<RetrieveOrdersResponse>
  getOrderById(params: GetOrderByIdParams): Promise<OrderDetails>
  cancelOrder(params: GetOrderByIdParams): Promise<void>
}

export type GetOrdersParams = {
  customerToken: string
  patientToken: string
  skip?: number
  limit?: number
}

export type GetOrderByIdParams = {
  customerToken: string
  orderId: string
  patientToken: string
}

export class OrderPaymentBadRequestError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'OrderPaymentBadRequestError'
  }
}

export class OrderSubmitBadRequest extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'OrderSubmitBadRequest'
  }
}

const { VPHARM_CUSTOMER_HEADER, VPHARM_PATIENT_HEADER } = sharedConstants

class OrderApiService implements OrderService {
  readonly baseUrl = `${process.env.REACT_APP_API_URL}`
  readonly defaultHeaders: AxiosRequestHeaders = { 'Content-Type': 'application/json' }

  async submitOrder(customerToken: string, patientToken: string, orderData: OrderParams): Promise<SubmitOrderResponse> {
    try {
      const url = `${this.baseUrl}/order`
      const config: AxiosRequestConfig = {
        headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken, [VPHARM_PATIENT_HEADER]: patientToken },
      }

      const result = await axiosAuth.post<SubmitOrderResponse>(url, orderData, config)

      return result.data
    } catch (error) {
      const msg = 'There was a problem while submitting your order. Please contact Patient Support'
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 402) {
          const message: string = error.response.data ? error.response.data : 'Your payment card was declined.'
          throw new OrderPaymentBadRequestError(message)
        } else if (error?.response?.status === 400) {
          const message: string = error.response.data ? error.response.data : msg
          throw new OrderSubmitBadRequest(message)
        } else {
          throw new Error(msg)
        }
      }
      throw new Error((error as Error).message)
    }
  }

  async getOrders(params: GetOrdersParams): Promise<RetrieveOrdersResponse> {
    const { customerToken, skip, limit, patientToken } = params
    try {
      const url = `${this.baseUrl}/orders`
      const config: AxiosRequestConfig = {
        params: { skip, limit },
        headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: customerToken, [VPHARM_PATIENT_HEADER]: patientToken },
      }

      const result = await axiosAuth.get<RetrieveOrdersResponse>(url, config)

      return result.data
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

  async getOrderById(params: GetOrderByIdParams): Promise<OrderDetails> {
    const url = `${this.baseUrl}/orders/${params.orderId}`
    const config: AxiosRequestConfig = {
      headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: params.customerToken, [VPHARM_PATIENT_HEADER]: params.patientToken },
    }

    const result = await axiosAuth.get<OrderDetails>(url, config)

    return result.data
  }

  async cancelOrder(params: GetOrderByIdParams): Promise<void> {
    try {
      const url = `${this.baseUrl}/orders/${params.orderId}/cancel_request`
      const config: AxiosRequestConfig = {
        headers: { ...this.defaultHeaders, [VPHARM_CUSTOMER_HEADER]: params.customerToken, [VPHARM_PATIENT_HEADER]: params.patientToken },
      }
      await axiosAuth.post<void>(url, {}, config)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }
}

export const orderService: OrderService = new OrderApiService()
