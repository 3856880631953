import { yupResolver } from '@hookform/resolvers/yup'
import { Spacer } from '@truepill/react-capsule'
import { formatToPhone } from '@vpharm-platform/shared'
import ErrorMessage from 'Components/ErrorMessage'
import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'
import ReCaptcha from 'react-google-recaptcha-enterprise'
import { Controller, useForm } from 'react-hook-form'
import { parseCheckboxStateForCapsule, parseTextFieldStateForCapsule } from 'utils'
import { SelfEnrollmentPatientInfo } from 'utils/validation'

import { ThemedTextField } from '../../../common/styledComponents/ThemedTextField'
import { useContentfulTheme } from '../../../hooks'
import { SHIPPING_STATES } from '../../../utils/states'
import { isOver18, isValidDate } from '../../../utils/validation/helpers'
import { daysOptions, genderOptions, GenderOptionType, monthOptions, OptionType, yearsOptions } from '../constants'
import SelfEnrollmentButton from '../SelfEnrollmentButton'
import SelfEnrollmentModal from '../SelfEnrollmentModal'
import { useSelfEnrollment } from '../useSelfEnrollment'
import {
  CheckBoxContainer,
  CityStateZipContainer,
  DobWrapper,
  FormWrapper,
  GenderWrapper,
  InfoIcon,
  InfoIconWrapper,
  InfoText,
  PatientFirstInitialContainer,
  PatientInfoInputContainer,
  PatientLastSuffixContainer,
  ReCaptchaWrapper,
  SectionHeaderText,
  StateZipContainer,
  StyledCheckbox,
  StyledSelect,
  TextFieldContainer,
} from './styledComponents'
import { GenderResponses } from './types'

interface PatientInfoFormValues {
  firstName: string
  middleInitial: string
  lastName: string
  patientPhoneNumber: string
  dob: {
    month: number | null
    year: number | null
    day: number | null
  }
  gender: GenderResponses | null
  suffix: string
  enrollCommunications: boolean
  address1: string
  address2: string
  city: string
  state: string
  zip: string
}

const PatientInfoForm = (): React.ReactElement => {
  const { theme } = useContentfulTheme()
  const { isModalOpen, setIsModalOpen } = useSelfEnrollment()
  const [captchaToken, setCaptchaToken] = useState<string | null>(null)
  const captchaRef = useRef<ReCaptcha | null>(null)
  const {
    register,
    control,
    getValues,
    setValue,
    formState: { errors, dirtyFields, isValid },
    handleSubmit,
    clearErrors,
    watch,
    setError,
  } = useForm<PatientInfoFormValues>({
    resolver: yupResolver(SelfEnrollmentPatientInfo),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      dob: {
        year: null,
        month: null,
        day: null,
      },
      firstName: '',
      lastName: '',
      gender: null,
      middleInitial: '',
      suffix: '',
      patientPhoneNumber: '',
      enrollCommunications: false,
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
    },
  })
  const patientInfo = watch()
  const enrollCommunicationField = watch('enrollCommunications')
  const isSubmitDisabled = !isValid || !captchaToken

  useEffect(() => {
    if (patientInfo.dob.day && patientInfo.dob.month && patientInfo.dob.year) {
      if (!isValidDate(patientInfo.dob.year, patientInfo.dob.month, patientInfo.dob.day)) {
        setError('dob', { message: 'Please enter a valid birth date' })
      } else if (!isOver18(patientInfo.dob.year, patientInfo.dob.month, patientInfo.dob.day)) {
        setError('dob', { message: 'Patients under 18 cannot be served' })
      } else {
        clearErrors('dob')
      }
    }
  }, [clearErrors, patientInfo.dob.day, patientInfo.dob.year, patientInfo.dob.month, setError])

  const handleSaveAndContinue = () => {
    setIsModalOpen(true)
  }

  const handleExpiredCaptcha = () => {
    setCaptchaToken(null)

    if (isModalOpen) {
      setIsModalOpen(false)
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>, category: string) => {
    switch (category) {
      case 'dob.day': {
        const key = e.key
        const previousValue = getValues('dob.day')
        const option = filterDob(previousValue, daysOptions, key)
        option && setValue('dob.day', option.value, { shouldValidate: true })
        break
      }
      case 'dob.month': {
        const key = e.key.toLowerCase()
        const previousValue = getValues('dob.month')
        const option = filterDob(previousValue, monthOptions, key)
        option && setValue('dob.month', option.value, { shouldValidate: true })
        break
      }
      case 'dob.year':
        {
          const key = e.key
          const previousValue = getValues('dob.year')
          const option = filterDob(previousValue, yearsOptions, key)
          option && setValue('dob.year', option.value, { shouldValidate: true })
        }
        break
      case 'gender':
        {
          const option = genderOptions.find(({ label }) => label.toLowerCase().startsWith(e.key.toLowerCase()))
          option && setValue('gender', option.value, { shouldValidate: true })
        }
        break
      case 'state': {
        const key = e.key.toLowerCase()
        const previousValue = getValues('state')
        const filteredState = SHIPPING_STATES.filter((stateInitial) => stateInitial.toLowerCase().startsWith(key))
        const optionInd = filteredState.findIndex((state) => state === previousValue)
        const option = filteredState[(optionInd + 1) % filteredState.length]
        option && setValue('state', option, { shouldValidate: true })
        break
      }
    }
  }

  return (
    <>
      <PatientInfoInputContainer>
        <FormWrapper onSubmit={handleSubmit(handleSaveAndContinue)}>
          <PatientFirstInitialContainer>
            <ThemedTextField
              label='Legal first name *'
              placeholder='First name'
              state={parseTextFieldStateForCapsule(errors.firstName, dirtyFields.firstName)}
              aria-labelledby='firstNameError'
              required
              helperText={errors.firstName?.message || ''}
              {...register('firstName')}
              vpTheme={theme}
            />
            <ThemedTextField
              label='Middle initial'
              placeholder='Middle initial'
              state={parseTextFieldStateForCapsule(errors.middleInitial, dirtyFields.middleInitial)}
              aria-labelledby='middleInitialError'
              helperText={errors.middleInitial?.message || ''}
              {...register('middleInitial')}
              vpTheme={theme}
            />
          </PatientFirstInitialContainer>
          <Spacer size='lg' />
          <PatientLastSuffixContainer>
            <ThemedTextField
              label='Legal last name *'
              placeholder='Last name'
              state={parseTextFieldStateForCapsule(errors.lastName, dirtyFields.lastName)}
              aria-labelledby='lastNameError'
              helperText={errors.lastName?.message || ''}
              {...register('lastName')}
              vpTheme={theme}
            />
            <ThemedTextField
              label='Suffix'
              placeholder='i.e., Sr., Jr.'
              state={parseTextFieldStateForCapsule(errors.suffix, dirtyFields.suffix)}
              aria-labelledby='suffixError'
              helperText={errors.suffix?.message || ''}
              {...register('suffix')}
              vpTheme={theme}
            />
          </PatientLastSuffixContainer>
          <Spacer size='lg' />
          <SectionHeaderText bold variant='body'>
            Date of birth *
          </SectionHeaderText>
          <Spacer size='md' />
          <DobWrapper>
            <TextFieldContainer onKeyDown={(e) => handleKeyDown(e, 'dob.month')}>
              <Controller
                control={control}
                name='dob.month'
                render={({ field }) => (
                  <StyledSelect
                    required
                    label='Month'
                    selectedKey='label'
                    value={monthOptions.find((option) => option.value === field.value)}
                    options={monthOptions}
                    onChange={(option: OptionType) => {
                      field.onChange(option?.value)
                    }}
                    placeholder='Month'
                    state={parseTextFieldStateForCapsule(errors.dob?.month, dirtyFields.dob?.month)}
                    aria-labelledby='monthError'
                    vpTheme={theme}
                  />
                )}
              />
            </TextFieldContainer>
            <TextFieldContainer onKeyDown={(e) => handleKeyDown(e, 'dob.day')}>
              <Controller
                control={control}
                name='dob.day'
                render={({ field }) => (
                  <StyledSelect
                    required
                    label='Day'
                    selectedKey='label'
                    value={daysOptions.find((option) => option.value === field.value)}
                    options={daysOptions}
                    onChange={(option: OptionType) => {
                      field.onChange(option?.value)
                    }}
                    placeholder='Day'
                    state={parseTextFieldStateForCapsule(errors.dob?.day, dirtyFields.dob?.day)}
                    aria-labelledby='dayError'
                    vpTheme={theme}
                  />
                )}
              />
            </TextFieldContainer>
            <TextFieldContainer onKeyDown={(e) => handleKeyDown(e, 'dob.year')}>
              <Controller
                control={control}
                name='dob.year'
                render={({ field }) => (
                  <StyledSelect
                    required
                    label='Year'
                    selectedKey='label'
                    value={yearsOptions.find((option) => option.value === field.value)}
                    options={yearsOptions}
                    onChange={(option: OptionType) => {
                      field.onChange(option?.value)
                    }}
                    placeholder='Year'
                    state={parseTextFieldStateForCapsule(errors.dob?.year, dirtyFields.dob?.year)}
                    aria-labelledby='yearError'
                    vpTheme={theme}
                  />
                )}
              />
            </TextFieldContainer>
            {errors.dob?.message && <ErrorMessage text={errors.dob.message} id='dobError' hideScreenReaderAlert={true} />}
          </DobWrapper>
          <Spacer size='lg' />
          <GenderWrapper>
            <TextFieldContainer onKeyDown={(e) => handleKeyDown(e, 'gender')}>
              <Controller
                control={control}
                name='gender'
                render={({ field }) => (
                  <StyledSelect
                    required
                    label='Sex assigned at birth *'
                    selectedKey='label'
                    value={genderOptions.find((option) => option.value === field.value)}
                    options={genderOptions}
                    onChange={(option: GenderOptionType) => {
                      field.onChange(option?.value)
                    }}
                    placeholder='Select'
                    state={parseTextFieldStateForCapsule(errors.gender, dirtyFields.gender)}
                    aria-labelledby='genderError'
                    vpTheme={theme}
                  />
                )}
              />
              {errors.gender?.message && <ErrorMessage text={errors.gender.message} id='genderError' hideScreenReaderAlert={true} />}
            </TextFieldContainer>
          </GenderWrapper>
          <Spacer size='lg' />
          <ThemedTextField
            label='Street address *'
            placeholder='Street address'
            {...register('address1')}
            helperText={errors.address1?.message || ''}
            state={parseTextFieldStateForCapsule(errors.address1, dirtyFields.address1)}
            required
            type='text'
            aria-labelledby='address1Error'
            vpTheme={theme}
          />
          <Spacer size='lg' />
          <ThemedTextField
            label='Suite, apartment, building, etc.  '
            placeholder='Suite, apartment, building, etc.  '
            {...register('address2')}
            helperText={errors.address2?.message || ''}
            state={parseTextFieldStateForCapsule(errors.address2, dirtyFields.address2)}
            type='text'
            aria-labelledby='address2Error'
            vpTheme={theme}
          />
          <Spacer size='lg' />
          <CityStateZipContainer>
            <ThemedTextField
              label='City *'
              placeholder='City'
              {...register('city')}
              helperText={errors.city?.message || ''}
              state={parseTextFieldStateForCapsule(errors.city, dirtyFields.city)}
              required
              type='text'
              aria-labelledby='cityError'
              vpTheme={theme}
            />
            <StateZipContainer onKeyDown={(e) => handleKeyDown(e, 'state')}>
              <Controller
                control={control}
                name='state'
                render={({ field }) => (
                  <StyledSelect
                    required
                    label='State *'
                    selectedKey='label'
                    value={SHIPPING_STATES.find((option) => option === field.value)}
                    options={SHIPPING_STATES}
                    onChange={(option: string) => {
                      field.onChange(option)
                    }}
                    placeholder='State'
                    state={parseTextFieldStateForCapsule(errors.state, dirtyFields.state)}
                    aria-labelledby='stateError'
                    vpTheme={theme}
                  />
                )}
              />
              {errors.state?.message && <ErrorMessage text={errors.state.message} id='stateError' hideScreenReaderAlert={true} />}
              <ThemedTextField
                label='Zip *'
                placeholder='Zip'
                {...register('zip')}
                helperText={errors.zip?.message || ''}
                state={parseTextFieldStateForCapsule(errors.zip, dirtyFields.zip)}
                required
                type='text'
                aria-labelledby='zipError'
                vpTheme={theme}
              />
            </StateZipContainer>
          </CityStateZipContainer>
          <Spacer size='lg' />
          <ThemedTextField
            label='Mobile number *'
            placeholder='Enter mobile number'
            {...register('patientPhoneNumber', {
              onChange: (e: ChangeEvent<HTMLInputElement>) => setValue('patientPhoneNumber', formatToPhone(e.target.value)),
            })}
            helperText={errors.patientPhoneNumber?.message || ''}
            state={parseTextFieldStateForCapsule(errors.patientPhoneNumber, dirtyFields.patientPhoneNumber)}
            required
            type='tel'
            aria-labelledby='patientPhoneNumberError'
            vpTheme={theme}
          />
          <Spacer size='lg' />
          <CheckBoxContainer vpTheme={theme}>
            <StyledCheckbox
              label='Send me SMS updates about my prescriptions'
              state={parseCheckboxStateForCapsule(errors.enrollCommunications)}
              aria-labelledby='enrollCommunicationsError'
              onCheckedChange={(checked: boolean) => setValue('enrollCommunications', checked)}
              checked={enrollCommunicationField}
            />
            {errors.enrollCommunications?.message && (
              <ErrorMessage text={errors.enrollCommunications.message} id='enrollCommunicationsError' hideScreenReaderAlert={true} />
            )}
          </CheckBoxContainer>
          <Spacer size='xl' />
          <InfoIconWrapper>
            <InfoIcon size={'25px'} vpTheme={theme} />
            <InfoText variant='body'>
              We use this information to match prescriptions to your account. You cannot update these details once your account has been created.
            </InfoText>
          </InfoIconWrapper>
          <Spacer size='xl' />
          <ReCaptchaWrapper>
            <ReCaptcha
              ref={captchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string}
              onChange={setCaptchaToken}
              onExpired={handleExpiredCaptcha}
            />
          </ReCaptchaWrapper>
          <Spacer size='xl' />
          <SelfEnrollmentButton buttonText={'Continue'} isDisabled={isSubmitDisabled} />
        </FormWrapper>
      </PatientInfoInputContainer>
      <SelfEnrollmentModal
        selfEnrollmentData={getValues()}
        captchaToken={captchaToken}
        onDismiss={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
      />
    </>
  )
}

/**
 * Return the next option in the list of options based on the previous value and the key pressed
 *
 * @param typeFilter - The type of filter to apply to the options
 * @param dobOptions - The list of options to filter
 * @param previousValue - The previous value selected
 * @param previousKey - The previous key pressed
 * @param key - The key pressed
 *
 * @returns The next option in the list of options based on the previous value and the key pressed
 *
 */
const filterOptions = (typeFilter: string, dobOptions: OptionType[], previousValue: number | null, previousKey: string, key: string) => {
  let typeValue: string
  if (typeFilter === 'day') {
    typeValue = previousKey
  }
  if (typeFilter === 'month') {
    typeValue = key
  }
  const filteredDobOptions = dobOptions.filter(({ label }) => label.toLowerCase().startsWith(typeValue))
  const optionInd = filteredDobOptions.findIndex(({ value }) => value === previousValue)
  const option = filteredDobOptions[(optionInd + 1) % filteredDobOptions.length]
  return option
}

const filterDob = (previousValue: number | null, dobOptions: OptionType[], key: string) => {
  const previousKey = dobOptions.find(({ value }) => value === previousValue)?.label.charAt(0) || ''
  let option
  if (key === previousKey) {
    option = filterOptions('day', dobOptions, previousValue, previousKey, key)
  } else {
    option = filterOptions('month', dobOptions, previousValue, previousKey, key)
  }
  return option
}

export default PatientInfoForm
