import { CardCvcElement } from '@stripe/react-stripe-js'
import { StripeCardCvcElementChangeEvent } from '@stripe/stripe-js'
import React, { PropsWithChildren, ReactElement, useState } from 'react'

import { useContentfulTheme } from '../../hooks'
import { InfoTooltip } from '../Tooltip'
import FieldContainer from './common/FieldContainer'
import FieldError from './common/FieldError'
import StripeElementContainer from './common/StripeElementContainer'
import StripeElementLabel from './common/StripeElementLabel'
import { CenteredElements, StyledTooltipContainer } from './styledComponents'

const cvvInfo = (
  <span>
    For most cards, the CVV is the last three digits on the back of your card.
    <br />
    <br />
    For American Express, it is the 4 digits on the front of your card.
  </span>
)

interface Props {
  onChange: (isComplete: boolean) => void
}

const CreditCardCvv = (props: PropsWithChildren<Props>): ReactElement => {
  const [focused, setFocused] = useState(false)
  const [error, setError] = useState(false)
  const { theme } = useContentfulTheme()

  const onCvvChange = (event: StripeCardCvcElementChangeEvent) => {
    if (event.error) {
      setError(true)
    } else {
      setError(false)
    }
    props.onChange(event.complete)
  }

  return (
    <FieldContainer>
      <CenteredElements>
        <StripeElementLabel required={false} vpTheme={theme}>
          CVV
        </StripeElementLabel>
        <StyledTooltipContainer>
          <InfoTooltip testId={'cvvTooltip'} label={'CVV'} position='right' text={cvvInfo} />
        </StyledTooltipContainer>
      </CenteredElements>
      <StripeElementContainer isDisabled={false} isFocus={focused} error={error} aria-labelledby='cvvError'>
        <CardCvcElement
          onBlur={() => setFocused(false)}
          onChange={onCvvChange}
          onFocus={() => setFocused(true)}
          options={{
            placeholder: 'CVV',
            style: { base: { fontFamily: 'Lato', fontSize: '16px' } },
          }}
        />
      </StripeElementContainer>
      {error && <FieldError text='Invalid CVV' />}
    </FieldContainer>
  )
}

export default CreditCardCvv
