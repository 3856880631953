import { FormularyMedication } from '@vpharm-platform/shared'
import { ReactElement } from 'react'

import { ThemedAutoComplete } from '../../../common/styledComponents/ThemedAutoComplete'
import { useContentfulTheme } from '../../../hooks'

type AutocompleteState = 'default' | 'complete' | 'error'

export interface FormularyMedicationWithFormattedName extends FormularyMedication {
  formattedName: string
  genericName: string | null
  drugName: string | null
}

interface AutocompleteDropdownProps {
  errors: boolean
  onChange: (value: FormularyMedicationWithFormattedName | null) => void
  value: FormularyMedicationWithFormattedName
  drugNames: FormularyMedicationWithFormattedName[]
  stateDrugSelected: AutocompleteState | undefined
}

const AutocompleteDropdown = ({ errors, onChange, value, drugNames, stateDrugSelected }: AutocompleteDropdownProps): ReactElement => {
  const { theme } = useContentfulTheme()
  return (
    <ThemedAutoComplete
      clearable={true}
      helperText={errors ? 'Select medication' : ''}
      id='medication_name'
      label='Medication name *'
      onChange={onChange}
      options={drugNames}
      selectedKey={'formattedName'}
      placeholder={'Start typing a medication name or use the dropdown.'}
      required
      state={errors ? 'error' : stateDrugSelected}
      value={value}
      vpTheme={theme}
    />
  )
}

export default AutocompleteDropdown
