import { Checkbox, Text } from '@truepill/react-capsule'
import { AlertCircle } from 'react-feather'
import styled from 'styled-components/macro'

import { ThemedSelect } from '../../../common/styledComponents/ThemedSelect'
import { ThemedComponent } from '../../../common/styledComponents/types'
import { mediaLargerThan } from '../../../common/styles/variables'

const SectionHeaderText = styled(Text)`
  margin-right: 8px;
`

const CityStateZipContainer = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: 12fr;

  ${mediaLargerThan.tablet} {
    grid-template-columns: 7fr 5fr;
  }
`

const StateZipContainer = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: 6fr 6fr;
  padding-top: 1rem;

  ${mediaLargerThan.tablet} {
    padding-top: 0;
    grid-template-columns: 2fr 3fr;
  }
`

const PatientInfoInputContainer = styled.div``

const PatientFirstInitialContainer = styled.div`
  display: grid;
  align-items: flex-end;

  grid-template-columns: 9fr 3fr;
  grid-column-gap: 1rem;
`

const PatientLastSuffixContainer = styled.div`
  display: grid;
  grid-template-columns: 9fr 3fr;
  grid-column-gap: 1rem;
`

const DobWrapper = styled.div`
  display: grid;
  grid-template-columns: 6fr 3fr 3fr;
  grid-column-gap: 1rem;
  max-width: 500px;
`

const GenderWrapper = styled.div`
  max-width: 400px;
`

const InfoIconWrapper = styled.div`
  display: grid;
  grid-template-columns: 25px 1fr;
  grid-column-gap: 0.5rem;
  max-width: 647px;
`

const InfoText = styled(Text)`
  line-height: 24px;
  font-weight: 400;
`

const InfoIcon = styled(AlertCircle)<ThemedComponent>`
  circle {
    fill: ${({ vpTheme }) => vpTheme.colors['functional-info-dark']};
  }
`

// SVG Nth Child to change sizing of icons
const StyledSelect = styled((props) => <ThemedSelect {...props} />)`
  ul {
    max-height: 10rem;
  }

  svg:nth-child(1) {
    min-width: 15px;
    max-width: 15px;
  }

  svg:nth-child(2) {
    min-width: 22px;
    max-width: 22px;
  }

  svg:nth-child(3) {
    min-width: 22px;
    max-width: 22px;
  }
`

const TextFieldContainer = styled.div``

const FormWrapper = styled.form``

const CheckBoxContainer = styled.div<ThemedComponent>`
  background-color: ${({ vpTheme }) => vpTheme.colors['gray-100']};
  min-height: 56px;
  border: 1px solid ${({ vpTheme }) => vpTheme.colors['gray-300']};
  padding: 1rem;
`

const StyledCheckbox = styled(Checkbox)`
  align-items: center;
  & ~ div {
    line-height: 150%;
  }
`

const ReCaptchaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export {
  CheckBoxContainer,
  CityStateZipContainer,
  DobWrapper,
  FormWrapper,
  GenderWrapper,
  InfoIcon,
  InfoIconWrapper,
  InfoText,
  PatientFirstInitialContainer,
  PatientInfoInputContainer,
  PatientLastSuffixContainer,
  ReCaptchaWrapper,
  SectionHeaderText,
  StateZipContainer,
  StyledCheckbox,
  StyledSelect,
  TextFieldContainer,
}
