import { yupResolver } from '@hookform/resolvers/yup'
import { Text } from '@truepill/react-capsule'
import { formatToPhone } from '@vpharm-platform/shared'
import { ChangeEvent } from 'react'
import { useForm } from 'react-hook-form'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { ThemedHeader } from '../../../common/styledComponents/ThemedHeader'
import { ThemedTextField } from '../../../common/styledComponents/ThemedTextField'
import { useContentfulTheme } from '../../../hooks'
import { parseTextFieldStateForCapsule } from '../../../utils'
import { AccountSettingEditPhone } from '../../../utils/validation'
import { AccountEditPhoneModalContentContainer, ButtonsContainer, EditPhoneFormWrapper, PhoneNumberInfoContainer } from './styledComponents'

interface AccountEditModalContentProps {
  phoneNumber?: string
  dismissModal: () => void
  onPhoneNumberEditSubmit: (phoneNumber: string) => Promise<void>
}

interface AccountEditPhoneFormValues {
  patientPhoneNumber: string
}

const AccountEditPhoneModalContent = ({ phoneNumber, dismissModal, onPhoneNumberEditSubmit }: AccountEditModalContentProps): React.ReactElement => {
  const {
    register,
    formState: { dirtyFields, errors, isSubmitting },
    setValue,
    handleSubmit,
    clearErrors,
  } = useForm<AccountEditPhoneFormValues>({
    resolver: yupResolver(AccountSettingEditPhone),
    reValidateMode: 'onChange',
    mode: 'onBlur',
  })
  const { theme } = useContentfulTheme()

  const onSubmit = async (formValues: AccountEditPhoneFormValues) => {
    await onPhoneNumberEditSubmit(formValues.patientPhoneNumber)
    dismissModal()
  }

  return (
    <AccountEditPhoneModalContentContainer>
      <ThemedHeader vpTheme={theme} variant='4xl'>
        Edit mobile number
      </ThemedHeader>
      <PhoneNumberInfoContainer>
        <Text bold>Current mobile number</Text>
        {phoneNumber ? formatToPhone(phoneNumber) : 'Unavailable'}
      </PhoneNumberInfoContainer>
      <EditPhoneFormWrapper onSubmit={handleSubmit(onSubmit)}>
        <ThemedTextField
          label='New mobile number'
          placeholder='(333) 888-5555'
          {...register('patientPhoneNumber', {
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              clearErrors('patientPhoneNumber')
              setValue('patientPhoneNumber', formatToPhone(e.target.value))
            },
          })}
          helperText={errors.patientPhoneNumber?.message || ''}
          state={parseTextFieldStateForCapsule(errors.patientPhoneNumber, dirtyFields.patientPhoneNumber)}
          required
          type='tel'
          aria-labelledby='patientPhoneNumberError'
          aria-label='Phone Number'
          vpTheme={theme}
        />
        <ButtonsContainer>
          <ThemedButton
            role='button'
            type='button'
            style={{ marginRight: '1rem' }}
            disabled={isSubmitting}
            variant='primary-text'
            onClick={dismissModal}
            vpTheme={theme}
          >
            Cancel
          </ThemedButton>
          <ThemedButton role='button' type='submit' disabled={false} variant='primary' vpTheme={theme}>
            Save changes
          </ThemedButton>
        </ButtonsContainer>
      </EditPhoneFormWrapper>
    </AccountEditPhoneModalContentContainer>
  )
}

export default AccountEditPhoneModalContent
