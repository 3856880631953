import { Spacer, Text } from '@truepill/react-capsule'
import { VpTheme } from '@vpharm-platform/shared'
import { ReactElement } from 'react'

import { VP_5903_INSURANCE_MGMT_UPDATE } from '../../constants'
import { useLDFlagsWithLocalStorage } from '../../hooks/useLDFlagsWithLocalStorage'
import { PatientInsurance } from '../../interfaces'
import { HydratedSavingsCard } from '../../utils/savingsCardUtils'
import { ButtonCard } from '../Fragments/ButtonCard'
import { ItemCard, ItemCardWrapper } from '../Fragments/ItemCard'
import { TagType } from '../Fragments/ItemCard/ItemCard'
import { StyledPlusCircle } from './styledComponents'

interface Props {
  theme: VpTheme
  patientInsuranceList?: PatientInsurance[]
  handleAddCard: () => void
  handleEditCard: (card: PatientInsurance | HydratedSavingsCard) => void
  handleDeleteCard: (card: PatientInsurance | HydratedSavingsCard) => void
  canAddCard: boolean
  savingsCardList?: HydratedSavingsCard[]
}

const SavedInsurances: React.FC<Props> = ({
  theme,
  patientInsuranceList,
  handleAddCard,
  handleEditCard,
  handleDeleteCard,
  canAddCard,
  savingsCardList,
}): ReactElement => {
  const { [VP_5903_INSURANCE_MGMT_UPDATE]: insuranceMgmtUpdate } = useLDFlagsWithLocalStorage([VP_5903_INSURANCE_MGMT_UPDATE])
  return (
    <>
      <ItemCardWrapper mobileBreakpoint='1100px'>
        {patientInsuranceList ? (
          patientInsuranceList.map((insurance) => (
            <div key={`insurance-${insurance.truepill_insurance_token}`}>
              <ItemCard
                tag={insurance.type === 'PRIMARY' ? 'PRIMARY INSURANCE' : 'SECONDARY INSURANCE'}
                tagType={insurance.type === 'PRIMARY' ? TagType.PRIMARY : TagType.SECONDARY}
                itemSubject='insurance'
                heading={`Cardholder Id: ${insurance.cardholder_id}`}
                line1={<Text>{`Rx BIN: ${insurance.rx_bin}`}</Text>}
                line2={
                  insurance?.pcn ? (
                    <Text>Rx PCN: {insurance.pcn}</Text>
                  ) : insurance?.rx_group ? (
                    <Text>Rx Group: {insurance.rx_group}</Text>
                  ) : (
                    <Spacer size='lg' />
                  )
                }
                line3={insurance.pcn && insurance.rx_group ? <Text>Rx Group: {insurance.rx_group}</Text> : <Spacer size='lg' />}
                editButtonText='Manage'
                deleteButtonText='Delete'
                editHandler={() => handleEditCard(insurance)}
                deleteHandler={() => handleDeleteCard(insurance)}
              />
            </div>
          ))
        ) : (
          <></>
        )}
        {insuranceMgmtUpdate && savingsCardList ? (
          savingsCardList.map((card) => (
            <div key={`card-${card.ndc}`}>
              <ItemCard
                tag='SAVINGS CARD'
                tagType={TagType.SAVINGS}
                itemSubject='savings-card'
                heading={`${card.prescription?.brandDisplayName}`}
                line1={`Cardholder Id: ${card.cardholderId}`}
                line2={<Text>{`Rx BIN: ${card.rxBin}`}</Text>}
                line3={card?.pcn ? <Text>Rx PCN: {card.pcn}</Text> : card?.rxGroup ? <Text>Rx Group: {card.rxGroup}</Text> : <Spacer size='lg' />}
                line4={card.pcn && card.rxGroup ? <Text>Rx Group: {card.rxGroup}</Text> : <Spacer size='lg' />}
                editButtonText='Manage'
                deleteButtonText='Delete'
                editHandler={() => handleEditCard(card)}
                deleteHandler={() => handleDeleteCard(card)}
              />
            </div>
          ))
        ) : (
          <></>
        )}
        {canAddCard && (
          <ButtonCard
            text={insuranceMgmtUpdate ? 'Add new card' : 'Add New Insurance'}
            icon={<StyledPlusCircle vpTheme={theme} className='w-md text-cap-info-dark mb-2xs inline-block' />}
            ariaLabel='add new insurance button'
            onClick={handleAddCard}
          />
        )}
      </ItemCardWrapper>
    </>
  )
}

export default SavedInsurances
