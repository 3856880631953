import { yupResolver } from '@hookform/resolvers/yup'
import { Spacer, Text } from '@truepill/react-capsule'
import React, { ChangeEvent, useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { ThemedHeader } from '../../../common/styledComponents/ThemedHeader'
import { ThemedTextField } from '../../../common/styledComponents/ThemedTextField'
import { useContentfulTheme } from '../../../hooks'
import { parseTextFieldStateForCapsule } from '../../../utils'
import { AccountSettingEditEmail } from '../../../utils/validation'
import { isValidEmail } from '../../../utils/validation/helpers'
import PasswordCheckForm from './PasswordCheckForm'
import { AccountEditEmailModalContentContainer, ButtonsContainer, DisclaimerText, EmailInfoContainer, FormWrapper } from './styledComponents'

interface AccountEditEmailFormValues {
  patientEmail: string
  patientEmailConfirmation: string
}

interface AccountEditEmailModalContentProps {
  email?: string
  dismissModal: () => void
  onEmailEditSubmit: (email: string) => Promise<void>
  isEmailAddressAvailable: (email: string) => Promise<boolean>
}

interface NewEmailAddressFormProps {
  email?: string
  form: UseFormReturn<AccountEditEmailFormValues, Record<string, unknown>>
  isEmailAddressAvailable: (email: string) => Promise<boolean>
}

const NewEmailAddressForm = ({ email, form, isEmailAddressAvailable }: NewEmailAddressFormProps): React.ReactElement => {
  const { theme } = useContentfulTheme()

  const {
    register,
    formState: { dirtyFields, errors },
    setValue,
    setError,
    trigger,
    clearErrors,
  } = form

  const [completePatientEmail, setCompletePatientEmail] = useState(false)
  const [completePatientEmailConfirmation, setCompletePatientEmailConfirmation] = useState(false)

  const onBlurEmail = async (e: ChangeEvent<HTMLInputElement>) => {
    setCompletePatientEmail(true)
    const newEmailAddress = e.target.value.trim().toLowerCase()
    if (!isValidEmail(newEmailAddress)) {
      return
    }

    const isEmailAvailable = await isEmailAddressAvailable(newEmailAddress)
    if (!isEmailAvailable) {
      setError(
        'patientEmail',
        {
          message:
            newEmailAddress === email
              ? 'Please provide an email address different from the current one.'
              : 'The email address you entered is already registered. Please provide a different email address.',
        },
        { shouldFocus: true },
      )
    }
  }

  const handleOnBlur = () => {
    if (completePatientEmail) {
      setCompletePatientEmailConfirmation(true)
    }
  }

  return (
    <>
      <ThemedTextField
        label='New email'
        placeholder='Enter new email'
        {...register('patientEmail', {
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            setCompletePatientEmail(false)
            setCompletePatientEmailConfirmation(false)
            clearErrors('patientEmail')
            clearErrors('patientEmailConfirmation')
            setValue('patientEmail', e.target.value)
            if (dirtyFields.patientEmailConfirmation) {
              trigger('patientEmailConfirmation')
            }
          },
          onBlur: onBlurEmail,
        })}
        helperText={errors.patientEmail?.message || ''}
        state={parseTextFieldStateForCapsule(errors.patientEmail, completePatientEmail)}
        required
        type='email'
        aria-labelledby='patientEmailError'
        aria-label='Email'
        vpTheme={theme}
      />
      <Spacer size='lg' />
      <ThemedTextField
        onPaste={(e) => {
          e.preventDefault()
          return false
        }}
        autoComplete='off'
        label='Confirm new email'
        placeholder='Enter new email'
        {...register('patientEmailConfirmation', {
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            clearErrors('patientEmailConfirmation')
            setCompletePatientEmailConfirmation(false)
            setValue('patientEmailConfirmation', e.target.value)
          },
          onBlur: handleOnBlur,
        })}
        helperText={errors.patientEmailConfirmation?.message || ''}
        state={parseTextFieldStateForCapsule(errors.patientEmailConfirmation, completePatientEmailConfirmation)}
        required
        type='email'
        aria-labelledby='patientEmailConfirmationError'
        aria-label='Email Confirmation'
        vpTheme={theme}
      />
    </>
  )
}

const AccountEditEmailModalContent = ({
  email,
  dismissModal,
  onEmailEditSubmit,
  isEmailAddressAvailable,
}: AccountEditEmailModalContentProps): React.ReactElement => {
  const [step, setStep] = useState(0)
  const form = useForm<AccountEditEmailFormValues>({
    resolver: yupResolver(AccountSettingEditEmail),
    reValidateMode: 'onChange',
    mode: 'onBlur',
  })
  const { theme } = useContentfulTheme()

  const onSubmit = async (formValues: AccountEditEmailFormValues) => {
    if (step === 1) {
      setStep(2)
      return
    }
    await onEmailEditSubmit(formValues.patientEmail)
    dismissModal()
  }

  if (step === 0) {
    return <PasswordCheckForm onSuccess={() => setStep(1)} />
  }

  return (
    <AccountEditEmailModalContentContainer>
      <ThemedHeader vpTheme={theme} variant='4xl'>
        {step === 1 ? 'Edit email' : 'Are you sure?'}
      </ThemedHeader>
      <DisclaimerText color={theme.colors['typography-medium']}>
        Updating your email address will change how you log in, requiring you to use the new email address for future logins. Additionally, all future
        communications will be sent to your updated email address.
      </DisclaimerText>
      {step === 1 && (
        <EmailInfoContainer>
          <Text bold>Current email</Text>
          {email}
        </EmailInfoContainer>
      )}
      <FormWrapper onSubmit={form.handleSubmit(onSubmit)}>
        {step === 1 && (
          <NewEmailAddressForm
            email={email}
            form={form as UseFormReturn<AccountEditEmailFormValues, Record<string, unknown>>}
            isEmailAddressAvailable={isEmailAddressAvailable}
          />
        )}
        <ButtonsContainer>
          <ThemedButton
            type='button'
            style={{ marginRight: '1rem' }}
            disabled={form.formState.isSubmitting}
            variant='primary-text'
            onClick={dismissModal}
            vpTheme={theme}
          >
            {step === 1 ? 'Cancel' : 'No, keep current email'}
          </ThemedButton>
          <ThemedButton type='submit' disabled={false} vpTheme={theme}>
            {step === 1 ? 'Save changes' : 'Yes, change email'}
          </ThemedButton>
        </ButtonsContainer>
      </FormWrapper>
    </AccountEditEmailModalContentContainer>
  )
}

export default AccountEditEmailModalContent
