import { ScreenReaderOnly } from '@truepill/react-capsule'
import { PatientIdentityFormValues } from '@vpharm-platform/shared'
import moment, { monthsShort } from 'moment'
import React, { KeyboardEvent, ReactElement, useEffect, useMemo, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'

import { ThemedTextField } from '../../common/styledComponents/ThemedTextField'
import { useContentfulTheme } from '../../hooks'
import { parseTextFieldStateForCapsule } from '../../utils'
import ErrorMessage from '../ErrorMessage'
import { DobWrapper, StyledSelect, TextFieldContainer } from './styledComponents'

type OptionType = { label: string; value: string }

// used for number inputs
const allowedKeys = ['Delete', 'Backspace', 'Enter', 'Escape', 'Tab', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

const allowNumbersOnlyWithLimit = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (!allowedKeys.includes(e.key)) {
    e.preventDefault()
  }
}

interface Props {
  form: UseFormReturn<PatientIdentityFormValues, Record<string, unknown>>
}

const DOBFormControl = ({ form }: Props): ReactElement => {
  const { theme } = useContentfulTheme()

  const {
    register,
    trigger,
    control,
    setValue,
    getValues,
    resetField,
    clearErrors,
    formState: { errors, dirtyFields },
  } = form
  const [dayErrorMessage, setDayErrorMessage] = useState('')

  // Revalidate date fields in relation
  const month = getValues('month')
  const year = getValues('year')

  useEffect(() => {
    if (!year) return
    resetField('month')
    setValue('month', month, { shouldValidate: true, shouldDirty: true })
  }, [month, year, resetField, setValue])

  useEffect(() => {
    if (!month || !year) return
    const day = getValues('day')
    resetField('day')
    setValue('day', day, { shouldValidate: true, shouldDirty: true })
  }, [month, year, getValues, resetField, setValue])

  const validateDay = () => {
    const selectedMonth = getValues('month') || '1'
    const selectedYear = getValues('year') || new Date().getFullYear()
    const numberOfDaysInMonth = moment(`${selectedYear}-${selectedMonth}`, 'YYYY-MM').daysInMonth()
    setDayErrorMessage(`Please enter a number between 01 to ${numberOfDaysInMonth}`)
  }

  const validateDayInput = (e: React.KeyboardEvent<HTMLInputElement>) => allowNumbersOnlyWithLimit(e)

  const validateYearInput = (e: React.KeyboardEvent<HTMLInputElement>) => allowNumbersOnlyWithLimit(e)

  const monthOptions = useMemo(
    () =>
      monthsShort().map((name, index) => ({
        label: name,
        value: `${index + 1}`,
      })),
    [],
  )

  const handleOnKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    const key = e.key.toLowerCase()
    const previousValue = getValues('month')
    const previousKey =
      monthOptions
        .find(({ value }) => value === previousValue)
        ?.label.charAt(0)
        .toLowerCase() || ''
    let option
    if (key === previousKey) {
      const filteredMonthOptions = monthOptions.filter(({ label }) => label.toLowerCase().startsWith(previousKey))
      const optionInd = filteredMonthOptions.findIndex(({ value }) => value === previousValue)
      option = filteredMonthOptions[(optionInd + 1) % filteredMonthOptions.length]
    } else {
      option = monthOptions.find(({ label }) => label.toLowerCase().startsWith(key))
    }
    option && setValue('month', option.value, { shouldValidate: true })
  }

  return (
    <DobWrapper>
      <TextFieldContainer onKeyDown={handleOnKeyDown}>
        <Controller
          control={control}
          defaultValue=''
          name='month'
          render={({ field }) => (
            <StyledSelect
              required
              label='Month *'
              selectedKey='label'
              value={monthOptions.find((option) => option.value === field.value)}
              options={monthOptions}
              onChange={(option: OptionType) => {
                clearErrors('month')
                field.onChange(option?.value)
              }}
              placeholder='Month'
              state={parseTextFieldStateForCapsule(errors.month, dirtyFields.month)}
              aria-labelledby='monthError'
              onBlur={() => trigger('month')}
              vpTheme={theme}
            />
          )}
        />
        {errors.month?.message && <ErrorMessage text='Please enter your birth month' id='monthError' hideScreenReaderAlert={true} />}
      </TextFieldContainer>
      <TextFieldContainer>
        <ThemedTextField
          required
          label='Day *'
          placeholder='DD'
          onKeyDown={validateDayInput}
          {...register('day', {
            onChange: () => {
              clearErrors('day')
            },
          })}
          state={parseTextFieldStateForCapsule(errors.day, dirtyFields.day)}
          aria-labelledby='dayError'
          maxLength={2}
          onBlur={() => trigger('day')}
          vpTheme={theme}
        />
        {errors.day?.message && (
          <ErrorMessage
            text={`Please enter a number between 01 to ${moment(
              `${getValues('year') || new Date().getFullYear()}-${getValues('month') || '1'}`,
              'YYYY-MM',
            ).daysInMonth()}`}
            id='dayError'
            hideScreenReaderAlert={true}
          />
        )}
      </TextFieldContainer>
      <TextFieldContainer>
        <ThemedTextField
          required
          label='Year *'
          placeholder='YYYY'
          onKeyDown={validateYearInput}
          {...register('year', {
            onChange: () => {
              validateDay()
              clearErrors('year')
            },
          })}
          state={parseTextFieldStateForCapsule(errors.year, dirtyFields.year)}
          aria-labelledby='yearError'
          maxLength={4}
          onBlur={() => trigger('year')}
          vpTheme={theme}
        />
        {errors.year?.message && (
          <ErrorMessage text={`Please enter a year before ${new Date().getFullYear()}`} id='yearErrorMessage' hideScreenReaderAlert={true} />
        )}
        {(errors.day || errors.month || errors.year) && (
          <ScreenReaderOnly role='alert' id='yearError'>
            {errors.year?.message ?? `Please enter a year before ${new Date().getFullYear()} `}
            {errors.month?.message ?? `Please enter your birth month`}
            {errors.day?.message ?? `${dayErrorMessage}`}
          </ScreenReaderOnly>
        )}
      </TextFieldContainer>
    </DobWrapper>
  )
}

export default DOBFormControl
