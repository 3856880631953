import { SEO, Spacer, Text } from '@truepill/react-capsule'
import { GetSavingCardResponse, MedicationInfo } from '@vpharm-platform/shared'
import LoadingAnimation from 'Components/LoadingAnimation'
import { AccountLayout } from 'Components/PageLayoutWithSidebar'
import { usePatientInsurance } from 'hooks/usePatientInsurance'
import { PatientInsurance, PatientInsuranceSubmitPayload } from 'interfaces'
import React, { Suspense, useCallback, useEffect, useState } from 'react'

import { ThemedHeader } from '../../../common/styledComponents/ThemedHeader'
import AddCardForm from '../../../Components/AddCardModal/AddCardModal'
import { useCustomerConfigContext } from '../../../Components/CustomerConfigProvider'
import CustomerCopyChange from '../../../Components/CustomerCopyChange'
import { withEmailVerified } from '../../../Components/Hoc/withEmailVerified'
import DeleteInsurance from '../../../Components/Insurances/DeleteInsurance'
import HaveInsuranceForm, { YesOrNo } from '../../../Components/Insurances/HaveInsuranceForm'
import InsuranceForm from '../../../Components/Insurances/InsuranceForm/InsuranceForm'
import SavedInsurances from '../../../Components/Insurances/SavedInsurances'
import SupportLink from '../../../Components/SupportLink'
import ToastMessage from '../../../Components/ToastMessage'
import { AccountPage, VP_5903_INSURANCE_MGMT_UPDATE } from '../../../constants'
import { useContentfulTheme } from '../../../hooks'
import { useAnalytics } from '../../../hooks/analytics-context'
import { InsurancePageMicrocopy } from '../../../hooks/contentful/types/microcopy'
import { useGetPageContent } from '../../../hooks/contentful/useGetPageContent'
import { useLDFlagsWithLocalStorage } from '../../../hooks/useLDFlagsWithLocalStorage'
import { canAddSavingsCard, HydratedSavingsCard } from '../../../utils/savingsCardUtils'
import ManualAddSavingsCard from '../../SavingsCardSignUp/screens/ManualAddSavingsCard'
import Container from '../Container'
import DeleteSavingsCard from '../SavingsCard/DeleteSavingsCardModal'
import EditSavingCardModal from '../SavingsCard/EditSavingCardModal'
import StyledHeader from '../StyledHeader'
import InsuranceBanner from './InsuranceBanner'
import SavingsCardBanner from './SavingsCardBanner'
import { CardFormModal, DeleteInsuranceModal, ManualAddSavingsCardModal } from './styledComponents'
import useManageSavingsCardModals from './useManageSavingsCardModals'

interface IToastMessage {
  message: string
  type: 'error' | 'success'
  displayCustomerSupport?: boolean
}

const InsurancePageContent: React.FunctionComponent = () => {
  const [toastMessage, setToastMessage] = useState<IToastMessage>()
  const { theme: customerTheme } = useContentfulTheme()
  const [insuranceFormModalOpen, setInsuranceFormModalOpen] = useState(false)
  const [addCardModalOpen, setAddCardModalOpen] = useState(false)
  const [haveInsuranceModalOpen, setHaveInsuranceModalOpen] = useState(false)
  const [deleteInsuranceModalOpen, setDeleteInsuranceModalOpen] = useState(false)
  const [currentPatientInsurance, setCurrentPatientInsurance] = useState<PatientInsurance | null>(null)
  const { trackButtonClickEvent } = useAnalytics()
  const featureFlags = useLDFlagsWithLocalStorage([VP_5903_INSURANCE_MGMT_UPDATE])
  const { [VP_5903_INSURANCE_MGMT_UPDATE]: insuranceMgmtUpdate } = featureFlags
  const [selectedSavingsCardMedication, setSelectedSavingsCardMedication] = useState<MedicationInfo>()

  const {
    isErrorLoading,
    isLoadingInsurance,
    isDeletingInsurance,
    patientInsuranceList,
    savingsCardList,
    deletePatientInsurance,
    submitPatientInsurance,
    prescriptions,
    setShouldRefetchPatientInsurance,
  } = usePatientInsurance()
  const { pharmCustomer } = useCustomerConfigContext()
  const { [VP_5903_INSURANCE_MGMT_UPDATE]: isInsuranceMgtmEnabled } = useLDFlagsWithLocalStorage([VP_5903_INSURANCE_MGMT_UPDATE])
  const { loading, error, content, seo, theme } = useGetPageContent<InsurancePageMicrocopy>('insurancePage')

  const {
    handleDeleteSavingsCardClick,
    handleManageSavingsCardClick,
    handleDeleteSavingsCard,
    handleSubmitSavingCard,
    uploadSavingCardImage,
    isDeleteSavingsCardModalOpen,
    isManageSavingsCardModalOpen,
    isDeletingSavingsCard,
    isSubmittingSavingsCard,
    isUploadingSavingCardImage,
    cardDisplayName,
    selectedSavingsCard,
    closeModals,
    savingsCardUrl,
  } = useManageSavingsCardModals({
    refreshPrescriptionList: setShouldRefetchPatientInsurance,
    setErrorMessage: (message) => setToastMessage({ message, type: 'error' }),
  })

  const canAddSavingsCards = prescriptions?.availableToOrder?.some(canAddSavingsCard) || prescriptions?.unavailableToOrder?.some(canAddSavingsCard)
  const canAddInsurance = (patientInsuranceList?.length || 0) < 2

  useEffect(() => {
    if (error || isErrorLoading) {
      setToastMessage({
        type: 'error',
        message: `We couldn't load your insurance details`,
      })
      console.error(error)
    }
  }, [error, isErrorLoading])

  const handleAddCard = () => {
    setAddCardModalOpen(true)
  }

  const handleAddInsurance = () => {
    setInsuranceFormModalOpen(true)
    setCurrentPatientInsurance(null)
    trackButtonClickEvent('open_add_new_insurance_modal', 'Add New Insurance', 'opens new insurance modal')
  }

  const handleHaveInsuranceContinue = (option: YesOrNo) => {
    if (option === YesOrNo.YES) {
      handleAddInsurance()
      setHaveInsuranceModalOpen(false)
    } else {
      setHaveInsuranceModalOpen(false)
    }
  }

  const handleAddSavingsCard = (selected: MedicationInfo) => {
    setSelectedSavingsCardMedication(selected)
    if (patientInsuranceList?.length === 0) setHaveInsuranceModalOpen(true)
    else {
      setHaveInsuranceModalOpen(false)
    }
  }

  const handleEditCard = (card: PatientInsurance | HydratedSavingsCard) => {
    const savings = card as GetSavingCardResponse
    if (savings?.cardholderId) {
      handleManageSavingsCardClick(savings)
      trackButtonClickEvent('open_edit_savings_modal', 'Manage', 'opens edit savings modal')
    } else {
      const insurance = card as PatientInsurance
      setCurrentPatientInsurance(insurance)
      setInsuranceFormModalOpen(true)
      trackButtonClickEvent('open_edit_insurance_modal', 'Manage', 'opens edit insurance modal')
    }
  }

  const handleClickUpdateInsuranceDetails = useCallback(
    (insurance: PatientInsurance) => {
      setCurrentPatientInsurance(insurance)
      setDeleteInsuranceModalOpen(false)
      setInsuranceFormModalOpen(true)
      trackButtonClickEvent('open_update_insurance_modal', 'Update insurance details', 'opens edit insurance modal')
    },
    [trackButtonClickEvent],
  )

  const handleDeleteCard = (card: PatientInsurance | HydratedSavingsCard) => {
    const savings = card as GetSavingCardResponse
    if (savings?.cardholderId) {
      handleDeleteSavingsCardClick(savings)
      trackButtonClickEvent('open_delete_savings_card', 'delete', 'opens delete savings card modal')
    } else {
      const insurance = card as PatientInsurance
      setCurrentPatientInsurance(insurance)
      setDeleteInsuranceModalOpen(true)
      trackButtonClickEvent('open_delete_insurance', 'delete', 'opens delete insurance modal')
    }
  }

  const handleSubmitPatientInsurance = async (values: PatientInsuranceSubmitPayload) => {
    const keys: (keyof Omit<PatientInsuranceSubmitPayload, 'insurance_image_name'>)[] = [
      'cardholder_id',
      'rx_bin',
      'rx_group',
      'pcn',
      'relationship_to_primary_cardholder',
      'type',
    ]
    const modifiedFields = keys.filter((key) => {
      const previousValue = currentPatientInsurance ? currentPatientInsurance[key] : null
      return (values[key] && previousValue !== values[key]) || !!values[key] !== !!previousValue
    })

    const isInsuranceImageModified = !!values.insurance_image_name || values.insurance_image_name === null

    // Always call the endpoint if insurance image name is a provided value, or if any fields were modified in the insurance
    if (modifiedFields.length || isInsuranceImageModified) {
      trackButtonClickEvent('save-or-update-patient-insurance', 'Save', currentPatientInsurance ? 'Update insurance' : 'Add new Insurance')
      const isSuccess = await submitPatientInsurance(values, currentPatientInsurance?.truepill_insurance_token as string | undefined)
      if (isSuccess) {
        setToastMessage({
          type: 'success',
          message: 'Your pharmacy insurance has been successfully added',
        })
      } else {
        setToastMessage({
          type: 'error',
          message: 'Something went wrong when trying to update your insurance details. Please try again later',
          displayCustomerSupport: true,
        })
      }
    }

    setInsuranceFormModalOpen(false)
  }

  const handleDeletePatientInsurance = async (insurance: PatientInsurance) => {
    const isSuccess = await deletePatientInsurance(insurance.truepill_insurance_token as string)
    if (isSuccess) {
      setToastMessage({
        type: 'success',
        message: 'Your pharmacy insurance has been successfully deleted',
      })
    } else {
      setToastMessage({
        type: 'error',
        message: 'Oops, something went wrong. Please try again',
        displayCustomerSupport: true,
      })
    }
    setDeleteInsuranceModalOpen(false)
    trackButtonClickEvent('confirm_delete_insurance', 'confirm delete', 'deletes insurance')
  }

  if (isLoadingInsurance || loading) {
    return <LoadingAnimation />
  }

  return (
    <AccountLayout selected={AccountPage.Insurance}>
      <SEO title={seo?.fields.title || `${pharmCustomer?.displayName} - Account Management`} useDefaults />
      <Container>
        <StyledHeader>
          <ThemedHeader vpTheme={theme} bold variant='4xl'>
            Insurance Management
          </ThemedHeader>
          <Spacer size='xl' />
          <Text>
            To pay for prescriptions using insurance, use the links below to add your insurance details to your account. You have the option to add
            supplemental insurance or copay coverage in addition to your primary insurance. Savings cards and discount cards can be added directly to
            eligible medications listed on the Prescription Manager.
          </Text>
        </StyledHeader>
        <Spacer size='xl' />
        <SavingsCardBanner theme={customerTheme} />
        <Spacer size='xl' />
        <SavedInsurances
          theme={theme}
          patientInsuranceList={patientInsuranceList}
          savingsCardList={savingsCardList}
          handleAddCard={insuranceMgmtUpdate ? handleAddCard : handleAddInsurance}
          handleEditCard={handleEditCard}
          handleDeleteCard={handleDeleteCard}
          canAddCard={canAddInsurance || canAddSavingsCards}
        />
        {content?.insuranceBannerMainHeader && (
          <InsuranceBanner
            theme={theme}
            iconUrl={content?.insuranceBannerIconUrl}
            mainHeader={content?.insuranceBannerMainHeader}
            backgroundColor={content?.insuranceBannerBackgroundColor}
            subtext={content?.insuranceBannerSubtext}
          />
        )}
        <CardFormModal
          isOpen={insuranceFormModalOpen}
          onDismiss={() => setInsuranceFormModalOpen(false)}
          aria-label={currentPatientInsurance ? 'edit insurance modal' : 'add new insurance modal'}
        >
          <InsuranceForm
            theme={theme}
            pageContent={content}
            currentInsurance={currentPatientInsurance}
            handleSubmitForm={handleSubmitPatientInsurance}
            handleCancelForm={() => {
              setInsuranceFormModalOpen(false)
              trackButtonClickEvent('cancel_add_insurance', 'cancel', 'cancels add insurance')
            }}
          />
        </CardFormModal>

        {insuranceMgmtUpdate && (
          <>
            <CardFormModal
              isOpen={addCardModalOpen}
              onDismiss={() => setAddCardModalOpen(false)}
              aria-label={currentPatientInsurance ? 'edit insurance modal' : 'add new insurance modal'}
            >
              <AddCardForm
                onDismiss={() => setAddCardModalOpen(false)}
                handleAddInsurance={handleAddInsurance}
                handleAddSavingsCard={handleAddSavingsCard}
                insuranceDisabled={!canAddInsurance}
                savingsCardDisabled={!canAddSavingsCards}
              />
            </CardFormModal>
            {selectedSavingsCardMedication && (
              <ManualAddSavingsCardModal
                isOpen={!!selectedSavingsCardMedication}
                onDismiss={() => setSelectedSavingsCardMedication(undefined)}
                aria-label={`add savings card modal for ${selectedSavingsCardMedication?.brandDisplayName}`}
              >
                <ManualAddSavingsCard
                  handleManualSubmitSavingsCard={() => setSelectedSavingsCardMedication(undefined)}
                  handleCancel={() => setSelectedSavingsCardMedication(undefined)}
                  prescriptionDisplayName={selectedSavingsCardMedication.brandDisplayName}
                  prescriptionToken={selectedSavingsCardMedication.prescriptionToken}
                  prescriptionNdc={selectedSavingsCardMedication.prescriptionNdc}
                  refreshInsurance={setShouldRefetchPatientInsurance}
                />
              </ManualAddSavingsCardModal>
            )}
          </>
        )}

        <CardFormModal isOpen={haveInsuranceModalOpen} onDismiss={() => setHaveInsuranceModalOpen(false)}>
          <HaveInsuranceForm handleContinue={handleHaveInsuranceContinue} theme={theme}></HaveInsuranceForm>
        </CardFormModal>

        <DeleteInsuranceModal
          isOpen={deleteInsuranceModalOpen}
          onDismiss={() => {
            setDeleteInsuranceModalOpen(false)
            trackButtonClickEvent('close_delete_insurance_modal', 'x', 'closes delete insurance modal')
          }}
          aria-label='delete insurance modal'
          isInsuranceMgtmEnabled={isInsuranceMgtmEnabled}
        >
          <DeleteInsurance
            theme={theme}
            currentInsurance={currentPatientInsurance as PatientInsurance}
            isDeletingInsurance={isDeletingInsurance}
            cancel={() => {
              setDeleteInsuranceModalOpen(false)
              trackButtonClickEvent('cancel_delete_insurance', 'cancel delete', 'cancels delete insurance')
            }}
            handleUpdateInsurance={handleClickUpdateInsuranceDetails}
            handleDeleteInsurance={handleDeletePatientInsurance}
          />
        </DeleteInsuranceModal>

        <DeleteInsuranceModal
          isInsuranceMgtmEnabled
          isOpen={isDeleteSavingsCardModalOpen}
          onDismiss={closeModals}
          aria-label='delete savings card modal'
        >
          <DeleteSavingsCard
            ndc={selectedSavingsCard?.ndc}
            medicationName={`${cardDisplayName}`}
            isDeletingSavingsCard={isDeletingSavingsCard}
            cancel={() => {
              trackButtonClickEvent('cancel_delete_savings_card', 'go back', 'cancels delete savings card')
              closeModals()
            }}
            handleDeleteSavingsCard={handleDeleteSavingsCard}
          ></DeleteSavingsCard>
        </DeleteInsuranceModal>

        {isManageSavingsCardModalOpen && selectedSavingsCard && selectedSavingsCard.prescription ? (
          <Suspense fallback={<></>}>
            <EditSavingCardModal
              savingsCardUrl={savingsCardUrl}
              prescriptionToken={selectedSavingsCard?.prescription?.prescriptionToken}
              handleSubmitForm={(values) => {
                trackButtonClickEvent('submit_manage_savings_card', 'save changes', 'submit manage savings card')
                handleSubmitSavingCard(values)
              }}
              uploadSavingCardImage={uploadSavingCardImage}
              isUploadingSavingCardImage={isUploadingSavingCardImage}
              isOpen={isManageSavingsCardModalOpen}
              isSubmittingForm={isSubmittingSavingsCard}
              onDismiss={() => {
                trackButtonClickEvent('cancel_manage_savings_card', 'cancel', 'cancels manage savings card')
                closeModals()
              }}
              modalHeaderName={`${cardDisplayName}`}
              ndc={selectedSavingsCard?.ndc}
              setError={(message) => setToastMessage({ type: 'error', message, displayCustomerSupport: false })}
            />
          </Suspense>
        ) : null}
      </Container>
      <ToastMessage
        state={toastMessage?.type}
        visible={!!toastMessage}
        timeout={3000}
        onTimeout={() => setToastMessage(undefined)}
        onDismiss={() => setToastMessage(undefined)}
      >
        <Text>
          {toastMessage?.message}{' '}
          {toastMessage?.displayCustomerSupport ? (
            <>
              or <SupportLink>contact Patient Support</SupportLink>
            </>
          ) : null}
        </Text>
      </ToastMessage>
      {content?.customerCopyChangeText && <CustomerCopyChange copyChangeText={content.customerCopyChangeText} />}
    </AccountLayout>
  )
}

export default withEmailVerified(InsurancePageContent)
