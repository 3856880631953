import { CardNumberElement } from '@stripe/react-stripe-js'
import { StripeCardNumberElementChangeEvent } from '@stripe/stripe-js'
import React, { PropsWithChildren, ReactElement, useState } from 'react'

import { useContentfulTheme } from '../../hooks'
import FieldError from './common/FieldError'
import StripeElementContainer from './common/StripeElementContainer'
import StripeElementLabel from './common/StripeElementLabel'

interface Props {
  label: string
  onChange: (isComplete: boolean) => void
}

const CreditCardNumber = (props: PropsWithChildren<Props>): ReactElement => {
  const [focused, setFocused] = useState(false)
  const [error, setError] = useState(false)
  const { theme } = useContentfulTheme()

  const onChange = (event: StripeCardNumberElementChangeEvent) => {
    if (event.error) {
      setError(true)
    } else {
      setError(false)
    }
    props.onChange(event.complete)
  }

  return (
    <>
      <StripeElementLabel required={false} vpTheme={theme}>
        {props.label}
      </StripeElementLabel>
      <StripeElementContainer isDisabled={false} error={error} isFocus={focused} aria-labelledby='cardNumberError'>
        <CardNumberElement
          onBlur={() => setFocused(false)}
          onFocus={() => setFocused(true)}
          options={{
            placeholder: 'Card number',
            showIcon: true,
            style: { base: { fontFamily: 'Lato', fontSize: '16px' } },
          }}
          onChange={onChange}
        />
      </StripeElementContainer>
      {error && <FieldError text='Invalid card number' />}
    </>
  )
}

export default CreditCardNumber
