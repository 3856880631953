import { yupResolver } from '@hookform/resolvers/yup'
import { Header, Toast } from '@truepill/react-capsule'
import { formatToPhone } from '@vpharm-platform/shared'
import React, { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

import { ThemedTextField } from '../../../common/styledComponents/ThemedTextField'
import OutlinedCard from '../../../Components/OutlinedCard'
import { ToastState } from '../../../constants/toastConstants'
import { useAuth } from '../../../context/auth-context'
import { useContentfulTheme, useCustomerProfile } from '../../../hooks'
import { usePatientToken } from '../../../hooks/usePatientToken'
import { contactService } from '../../../services/ContactService'
import { IContactFormSectionFields } from '../../../types/generated/contentful'
import { parseTextFieldStateForCapsule } from '../../../utils'
import { ContactFormValidationSchema } from '../../../utils/validation'
import {
  ContactFormBoxesContainer,
  ContactFormContainer,
  ContactUsSelect,
  Container,
  Form,
  SubmitButton,
  Subtitle,
  TextArea,
  TextFieldContainer,
  TextFieldWrapper,
} from './styledComponents'

export interface FormValues {
  name: string
  email: string
  phone: string
  subject: string
  message: string
  topic: string
  patientToken: string
}

const ContactUsForm = (props: IContactFormSectionFields): React.ReactElement => {
  const { title, subtitle, contactCards, contactFormTopics } = props
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const issue = searchParams.get('issue')
  const { theme } = useContentfulTheme()
  const {
    authState: { isAuthenticated },
  } = useAuth()

  const selectedPatientToken = usePatientToken()

  const getDefaultTopic = (): string | undefined => {
    let topic
    if (issue === 'account_creation') {
      topic = 'I am having an issue creating an account'
    }
    return topic
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields, isValid },
    reset,
    control,
  } = useForm<FormValues>({
    resolver: yupResolver(ContactFormValidationSchema),
    defaultValues: {
      topic: issue ? getDefaultTopic() : undefined,
      name: '',
      email: '',
      phone: '',
      message: '',
      subject: '',
    },
    mode: 'onChange',
  })

  const [error, setError] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)

  const { customerProfile } = useCustomerProfile()
  const { vpharmCustomerToken } = customerProfile

  const handleSubmitButtonClick = useCallback(
    async (formValues: FormValues) => {
      if (!isValid) return

      setSuccess(false)
      setError(false)

      if (isAuthenticated) {
        formValues.patientToken = selectedPatientToken
      }

      try {
        await contactService.contactPatient(formValues, vpharmCustomerToken)
        reset()
        setSuccess(true)
      } catch (error) {
        setError(true)
      }
    },
    [isAuthenticated, isValid, reset, selectedPatientToken, vpharmCustomerToken],
  )

  const handlePhoneNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue('phone', formatToPhone(e.target.value), { shouldValidate: true })
    },
    [setValue],
  )

  const handleToastTimeout = useCallback(() => {
    setError(false)
    setSuccess(false)
  }, [])

  return (
    <Container {...props} vpTheme={theme}>
      {contactCards && contactCards.length > 0 && (
        <ContactFormBoxesContainer>
          {contactCards.map((contactCard) => (
            <OutlinedCard
              key={contactCard.sys.id}
              title={contactCard.fields.title}
              subtitle={contactCard.fields.subtitle}
              bodyTextOne={contactCard.fields.bodyTextOne}
              bodyTextTwo={contactCard.fields.bodyTextTwo}
              buttonLink={contactCard.fields.buttonLink}
              buttonText={contactCard.fields.buttonText}
            />
          ))}
        </ContactFormBoxesContainer>
      )}
      <ContactFormContainer vpTheme={theme}>
        <Toast visible={error || success} onTimeout={handleToastTimeout} state={success ? ToastState.SUCCESS : ToastState.ERROR} timeout={5000}>
          {success ? 'Message successfully sent!' : 'Something went wrong. Please refresh your browser and try again.'}
        </Toast>
        <Header variant='4xl'>{title}</Header>
        <Subtitle>{subtitle}</Subtitle>
        <Form onSubmit={handleSubmit(handleSubmitButtonClick)}>
          <TextFieldWrapper>
            <ThemedTextField
              label='Full name *'
              maxLength={80}
              state={parseTextFieldStateForCapsule(errors.name, dirtyFields.name)}
              {...register('name')}
              vpTheme={theme}
            />
          </TextFieldWrapper>
          <TextFieldContainer>
            <TextFieldWrapper>
              <ThemedTextField
                label='Your email address *'
                maxLength={80}
                state={parseTextFieldStateForCapsule(errors.email, dirtyFields.email)}
                {...register('email')}
                vpTheme={theme}
              />
            </TextFieldWrapper>
          </TextFieldContainer>
          <TextFieldContainer>
            <TextFieldWrapper>
              <ThemedTextField
                label='Your phone number (optional)'
                maxLength={40}
                state={parseTextFieldStateForCapsule(errors.phone, dirtyFields.phone)}
                {...register('phone', {
                  onChange: handlePhoneNumberChange,
                })}
                vpTheme={theme}
              />
            </TextFieldWrapper>
          </TextFieldContainer>
          {contactFormTopics && contactFormTopics.length > 0 && (
            <TextFieldWrapper>
              <Controller
                name='topic'
                control={control}
                render={({ field }) => (
                  <ContactUsSelect
                    state={parseTextFieldStateForCapsule(errors.topic, dirtyFields.topic)}
                    options={contactFormTopics}
                    value={contactFormTopics.find((option) => option === field.value)}
                    label='Topic *'
                    onChange={(val: string) => {
                      if (val) {
                        setValue('topic', val, {
                          shouldDirty: true,
                          shouldTouch: true,
                          shouldValidate: true,
                        })
                      }
                    }}
                    vpTheme={theme}
                  />
                )}
              />
            </TextFieldWrapper>
          )}
          <TextFieldWrapper>
            <ThemedTextField
              label='Subject *'
              maxLength={80}
              state={parseTextFieldStateForCapsule(errors.subject, dirtyFields.subject)}
              {...register('subject')}
              vpTheme={theme}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <TextArea
              label='Message *'
              type='textarea'
              placeholder='Type your message here...'
              state={parseTextFieldStateForCapsule(errors.message, dirtyFields.message)}
              {...register('message')}
              vpTheme={theme}
            />
          </TextFieldWrapper>

          <SubmitButton type='submit' name='submit' vpTheme={theme}>
            Submit
          </SubmitButton>
        </Form>
      </ContactFormContainer>
    </Container>
  )
}

export default ContactUsForm
