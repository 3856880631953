import { yupResolver } from '@hookform/resolvers/yup'
import { Checkbox, Spacer, Text, Theme, Toggle } from '@truepill/react-capsule'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { ThemedTextField } from '../../../common/styledComponents/ThemedTextField'
import { useContentfulTheme } from '../../../hooks'
import { useAnalytics } from '../../../hooks/analytics-context'
import { PatientAddress } from '../../../interfaces'
import { formatAddressToSingleLine } from '../../../pages/AccountManagement/Addresses/data/addressSettings'
import { parseTextFieldStateForCapsule } from '../../../utils'
import { ShippingValidationSchema } from '../../../utils/validation'
import { GreyBackgroundContainer, SplitContainer, WhiteBackgroundContainer } from '../../AddressForms/styledComponents'
import { UpDownChevron } from '../../AddressForms/upDownChevron'
import AutoCompleteAddressFields from './AutoCompleteAddressFields'
import ManualAddressFields from './ManualAddressFields'
import { StyledActionContainer, StyledBannerAlert, ToggleAutoCompleteAddressEntry } from './styledComponents'

interface Props {
  addressToEdit?: PatientAddress
  submitButtonText?: string
  askDefault?: boolean
  askShouldSave?: boolean
  onCancel?: () => void
  onSubmit: (address: PatientAddress) => Promise<{ error?: string }>
}

const AddressForm = ({ addressToEdit, askDefault, askShouldSave, submitButtonText = 'Save', onCancel, onSubmit }: Props): ReactElement => {
  const [isAutoCompleteMode, setIsAutoCompleteMode] = useState(true)
  const [isModified, setIsModified] = useState(false)
  const [defaultToggle, setDefaultToggle] = useState(false)
  const [saveChecked, setSaveChecked] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const { trackToggleEvent, trackButtonClickEvent, trackCheckboxToggleClickEvent } = useAnalytics()
  const { theme } = useContentfulTheme()

  const form = useForm<PatientAddress>({
    resolver: yupResolver(ShippingValidationSchema),
    delayError: 1000,
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      address2: '',
      isSaved: true,
    },
  })

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    formState: { errors, dirtyFields, isValid, isSubmitting },
  } = form

  useEffect(() => {
    if (addressToEdit) {
      const fullAddress = formatAddressToSingleLine(addressToEdit)
      reset(
        {
          ...addressToEdit,
          fullAddress,
        },
        { keepDefaultValues: true },
      )
    }
  }, [addressToEdit, reset, setValue])

  const toggleAutoCompleteMode = () => {
    trackButtonClickEvent(
      'add_manual_address',
      isAutoCompleteMode ? 'Add address manually instead' : 'Find your address',
      'toggle_auto_complete_mode',
    )
    if (isAutoCompleteMode && addressToEdit) {
      const fullAddress = formatAddressToSingleLine(addressToEdit)
      reset(
        {
          ...addressToEdit,
          address2: addressToEdit.address2 ? addressToEdit.address2 : '',
          fullAddress,
        },
        { keepDefaultValues: true },
      )
    }
    setIsAutoCompleteMode(!isAutoCompleteMode)
  }

  const handleDefaultCheckbox = useCallback(
    (isChecked: boolean) => {
      trackCheckboxToggleClickEvent('set_default_address_checked', isChecked)
      setValue('isDefault', isChecked)
    },
    [setValue, trackCheckboxToggleClickEvent],
  )

  const handleDefaultToggle = useCallback(() => {
    const newState = !defaultToggle
    setDefaultToggle(newState)
    trackToggleEvent('set_default_address_checked', newState)
    setValue('isDefault', newState)
    if (newState) {
      setSaveChecked(true)
      setValue('isSaved', newState)
    }
  }, [defaultToggle, setValue, trackToggleEvent])

  const handleSaveCheckbox = useCallback(() => {
    const newState = !saveChecked
    setSaveChecked(newState)
    trackCheckboxToggleClickEvent('save_address_checked', newState)
    setValue('isSaved', newState)
  }, [saveChecked, setValue, trackCheckboxToggleClickEvent])

  const handleCancel = useCallback(() => {
    trackButtonClickEvent('cancel_add_address', 'Cancel', 'cancels add address')
    if (onCancel) {
      onCancel()
    }
  }, [onCancel, trackButtonClickEvent])

  const handleSubmitForm = async (address: PatientAddress) => {
    setErrorMessage(null)
    const result = await onSubmit(address)
    if (result.error) {
      setErrorMessage(result.error)
    }
  }

  return (
    <FormProvider {...form}>
      <Theme theme={theme}>
        {errorMessage && (
          <>
            <StyledBannerAlert state='error'>
              <Text>{errorMessage}</Text>
            </StyledBannerAlert>
            <Spacer size='lg' />
          </>
        )}
        <form data-testid='address-form' onSubmit={handleSubmit(handleSubmitForm)}>
          <ThemedTextField
            label='Full Name'
            {...register('name', {
              onChange: () => {
                clearErrors('name')
                setIsModified(true)
              },
            })}
            data-testid='manual-add-name'
            placeholder='Full Name'
            helperText={errors.name ? 'Please enter Full Name' : ''}
            state={parseTextFieldStateForCapsule(errors.name, dirtyFields.name)}
            aria-label='Name'
            required
            vpTheme={theme}
          />

          <Spacer />

          {isAutoCompleteMode ? (
            <AutoCompleteAddressFields setIsModified={setIsModified} />
          ) : (
            <ManualAddressFields isEditMode={!!addressToEdit} setIsModified={setIsModified} />
          )}

          {!askDefault && !askShouldSave && addressToEdit && !addressToEdit.isDefault && (
            <>
              <Spacer size='lg' />
              <GreyBackgroundContainer vpTheme={theme} padding border>
                <Checkbox
                  id='setDefault'
                  onCheckedChange={handleDefaultCheckbox}
                  indicatorCss={{
                    backgroundColor: '$primary-500',
                  }}
                />
                <Text as='label' htmlFor='setDefault'>
                  Set as default shipping address
                </Text>
              </GreyBackgroundContainer>
            </>
          )}

          {askShouldSave && !askDefault && (
            <>
              <Spacer size='lg' />
              <GreyBackgroundContainer vpTheme={theme} padding border>
                <Checkbox
                  id='saveAddress'
                  checked={saveChecked}
                  onCheckedChange={handleSaveCheckbox}
                  indicatorCss={{
                    backgroundColor: '$primary-500',
                  }}
                />
                <Text as='label' htmlFor='saveAddress'>
                  Save shipping address for a future purchase
                </Text>
              </GreyBackgroundContainer>
            </>
          )}

          {askDefault && !askShouldSave && (
            <>
              <Spacer size='lg' />
              <GreyBackgroundContainer vpTheme={theme} padding border>
                <Toggle
                  checked={defaultToggle}
                  onChange={handleDefaultToggle}
                  switchCss={{
                    '&[data-enabled="true"]': {
                      backgroundColor: '$primary-500',
                    },
                  }}
                />
                <Text as='label' onClick={handleDefaultToggle}>
                  Set as default
                </Text>
              </GreyBackgroundContainer>
            </>
          )}

          {askShouldSave && askDefault && (
            <>
              <Spacer size='lg' />
              <SplitContainer border={theme.colors['gray-300']}>
                <GreyBackgroundContainer vpTheme={theme}>
                  <Checkbox
                    id='saveAddress'
                    checked={saveChecked}
                    onCheckedChange={handleSaveCheckbox}
                    disabled={defaultToggle}
                    indicatorCss={{
                      backgroundColor: '$primary-500',
                    }}
                  />
                  <Text as='label' htmlFor='saveAddress'>
                    Save shipping address for a future purchase
                  </Text>
                </GreyBackgroundContainer>
                <WhiteBackgroundContainer vpTheme={theme}>
                  <Toggle
                    checked={defaultToggle}
                    onChange={handleDefaultToggle}
                    switchCss={{
                      '&[data-enabled="true"]': {
                        backgroundColor: '$primary-500',
                      },
                    }}
                  />
                  <Spacer size='sm' />
                  <Text as='label' onClick={handleDefaultToggle}>
                    Set as default
                  </Text>
                </WhiteBackgroundContainer>
              </SplitContainer>
            </>
          )}

          <Spacer size='xl' />

          <ToggleAutoCompleteAddressEntry type='button' variant='primary-text' onClick={toggleAutoCompleteMode} vpTheme={theme}>
            {isAutoCompleteMode ? 'Add address manually instead' : 'Find your address'}
            <Spacer size='sm' />
            <UpDownChevron flipped={!isAutoCompleteMode} />
          </ToggleAutoCompleteAddressEntry>

          <Spacer size='lg' />

          <StyledActionContainer>
            {onCancel && (
              <ThemedButton type='button' data-testid='manual-cancel-btn' variant='primary-text' onClick={handleCancel} vpTheme={theme}>
                Cancel
              </ThemedButton>
            )}
            <ThemedButton data-testid='submit-address-btn' type='submit' disabled={!isValid || !isModified || isSubmitting} vpTheme={theme}>
              {submitButtonText}
            </ThemedButton>
          </StyledActionContainer>
        </form>
      </Theme>
    </FormProvider>
  )
}

export default AddressForm
