import { yupResolver } from '@hookform/resolvers/yup'
import { Header, Spacer, Text } from '@truepill/react-capsule'
import { formatToPhone } from '@vpharm-platform/shared'
import { ChangeEvent } from 'react'
import { useForm } from 'react-hook-form'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { ThemedTextField } from '../../../common/styledComponents/ThemedTextField'
import { useContentfulTheme } from '../../../hooks'
import { parseTextFieldStateForCapsule } from '../../../utils'
import { AccountSettingEditPhone } from '../../../utils/validation'
import { ButtonsContainer, PhoneNumberActionContainer, Wrapper } from './styledComponents'

interface AccountEditModalContentProps {
  initialNumber?: string
  dismissModal: () => void
  handleSaveNumber: (phoneNumber: string, smsOptIn: boolean) => void
  smsOptIn: boolean
}

interface AccountEditPhoneFormValues {
  patientPhoneNumber: string
}

const PhoneNumberModal = ({ initialNumber, dismissModal, handleSaveNumber, smsOptIn }: AccountEditModalContentProps): React.ReactElement => {
  const {
    register,
    formState: { dirtyFields, errors, isSubmitting, touchedFields },
    setValue,
    handleSubmit,
  } = useForm<AccountEditPhoneFormValues>({
    resolver: yupResolver(AccountSettingEditPhone),
    mode: 'onChange',
    defaultValues: {
      patientPhoneNumber: initialNumber,
    },
  })
  const { theme } = useContentfulTheme()

  const onSubmit = (formValues: AccountEditPhoneFormValues) => {
    handleSaveNumber(formValues.patientPhoneNumber, smsOptIn)
    dismissModal()
  }

  return (
    <PhoneNumberActionContainer>
      <Header variant='2xl'>{initialNumber ? 'Modify number' : 'Add number'}</Header>
      <Spacer size='md' />
      {smsOptIn && (
        <Wrapper data-testid='savings_card-to-be-deleted'>
          <Text>Where would you like to receive your SMS updates about your prescription?</Text>
        </Wrapper>
      )}
      <Spacer size='md' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ThemedTextField
          label='New mobile number'
          placeholder='(333) 888-5555'
          {...register('patientPhoneNumber', {
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              setValue('patientPhoneNumber', formatToPhone(e.target.value))
            },
          })}
          helperText={dirtyFields.patientPhoneNumber && touchedFields.patientPhoneNumber ? errors.patientPhoneNumber?.message : undefined}
          state={parseTextFieldStateForCapsule(errors.patientPhoneNumber, dirtyFields.patientPhoneNumber && touchedFields.patientPhoneNumber)}
          required
          type='tel'
          aria-labelledby='phoneNumberError'
          aria-label='Phone Number'
          vpTheme={theme}
        />
        <Spacer size='md' />
        <ButtonsContainer>
          <ThemedButton
            role='button'
            type='button'
            className='button'
            style={{ marginRight: '1rem', width: 'auto' }}
            disabled={isSubmitting}
            variant='primary-text'
            onClick={dismissModal}
            vpTheme={theme}
          >
            Cancel
          </ThemedButton>
          <ThemedButton role='button' type='submit' disabled={false} variant='primary' vpTheme={theme} style={{ width: 'auto' }}>
            Update
          </ThemedButton>
        </ButtonsContainer>
      </form>
    </PhoneNumberActionContainer>
  )
}

export default PhoneNumberModal
