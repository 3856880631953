import { Header, SEO, Spacer } from '@truepill/react-capsule'
import { OrderInfo } from '@vpharm-platform/shared'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Scroll from 'react-scroll'
import { useRecoilValue } from 'recoil'

import { ThemedHeader } from '../../common/styledComponents/ThemedHeader'
import LoadingAnimation from '../../Components/LoadingAnimation'
import SupportLink from '../../Components/SupportLink'
import ToastMessage from '../../Components/ToastMessage'
import { useCustomerProfile } from '../../hooks'
import { useAnalytics } from '../../hooks/analytics-context'
import { OrderPageMicrocopy } from '../../hooks/contentful/types/microcopy'
import { useGetPageContent } from '../../hooks/contentful/useGetPageContent'
import { selectedPatientTokenAtom } from '../../persistRecoil'
import { orderService } from '../../services'
import OrderItem from './OrderItems/OrderItem'
import { ContentWrapper, OrdersContainer, TryAgainButton } from './styledComponents'

const Orders: React.FunctionComponent = () => {
  const { customerProfile } = useCustomerProfile()
  const selectedPatientToken = useRecoilValue(selectedPatientTokenAtom)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [showErrorToast, setShowErrorToast] = useState<boolean>(false)
  const [showOrderNotFoundToast, setShowOrderNotFoundToast] = useState<boolean>(false)
  const [showError, setShowErrror] = useState<boolean>(false)
  const [orders, setOrders] = useState<OrderInfo[]>([])
  const [totalOrders, setTotalOrders] = useState<number>(0)
  const { trackButtonClickEvent } = useAnalytics()
  const { theme, seo } = useGetPageContent<OrderPageMicrocopy>('orderPage')

  const getOrdersForPatient = useCallback(async () => {
    const customerToken = customerProfile.vpharmCustomerToken
    try {
      const orderResponse = await orderService.getOrders({ customerToken, limit: 10, patientToken: selectedPatientToken })
      setShowErrror(false)
      setOrders(orderResponse.orders)
      setTotalOrders(orderResponse.totalOrders)
    } catch (ex) {
      setShowErrorToast(true)
      setShowErrror(true)
    } finally {
      setIsLoading(false)
    }
  }, [customerProfile.vpharmCustomerToken, selectedPatientToken])

  const handleDismiss = () => {
    setShowErrorToast(false)
    setShowOrderNotFoundToast(false)
  }

  const handleRetryButtonClick = useCallback(() => getOrdersForPatient(), [getOrdersForPatient])

  useEffect(() => {
    getOrdersForPatient()
  }, [getOrdersForPatient])

  const calculateOffSetIndex = (orders: OrderInfo[], orderId: string, element: HTMLElement) => {
    const index = orders.findIndex((item) => item.identifier === orderId)
    if (index === 0) {
      return -100
    }
    return element.clientHeight * index - 100
  }

  useEffect(() => {
    if (location.hash.length > 0 && !isLoading) {
      const id = location.hash.substring(1)
      const element = document.getElementById(id)
      if (element) {
        Scroll.scroller.scrollTo(id, {
          duration: 1500,
          delay: 100,
          smooth: true,
          isDynamic: true,
          offset: calculateOffSetIndex(orders, id, element),
        })
      } else {
        // setShowOrderNotFoundToast(true) TODO: uncomment this when we have definition how to deal with Orders that not load into the first 10 elements
        Scroll.animateScroll.scrollToTop()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  if (isLoading) {
    return <LoadingAnimation />
  }

  const fetchMoreOrders = async () => {
    try {
      const customerToken = customerProfile.vpharmCustomerToken
      const orderResponse = await orderService.getOrders({ customerToken, skip: orders.length, limit: 10, patientToken: selectedPatientToken })
      setOrders([...orders, ...orderResponse.orders])
    } catch (ex) {
      setShowErrorToast(true)
    }
  }

  return (
    <Fragment>
      <OrdersContainer id={`container-order-${customerProfile.vpharmCustomerToken}`}>
        {seo?.fields.title && <SEO title={seo.fields.title || customerProfile.customerName} description={seo.fields.description} useDefaults />}
        <Spacer size='3xl' />
        <ThemedHeader vpTheme={theme} bold variant='4xl' data-testid='header-orders'>
          Orders
        </ThemedHeader>
        {showErrorToast && (
          <ToastMessage
            borderLeft={true}
            color='pastel'
            className='toast-message'
            icon={true}
            onDismiss={handleDismiss}
            position={{ vertical: 'top', horizontal: 'center' }}
            state={'error'}
            visible={showErrorToast}
            onTimeout={handleDismiss}
          >
            There has been an error retrieving the orders for this account
          </ToastMessage>
        )}
        {showOrderNotFoundToast && (
          <ToastMessage
            borderLeft={true}
            color='pastel'
            className='toast-message'
            icon={true}
            onDismiss={handleDismiss}
            position={{ vertical: 'top', horizontal: 'center' }}
            state={'info'}
            visible={showOrderNotFoundToast}
            onTimeout={handleDismiss}
          >
            Your order cannot be found. Please email <SupportLink />
          </ToastMessage>
        )}
        <ContentWrapper>
          <InfiniteScroll
            dataLength={orders.length}
            next={fetchMoreOrders}
            hasMore={orders.length < totalOrders}
            loader={<LoadingAnimation />}
            style={{ overflow: 'hidden' }}
          >
            {orders &&
              orders.length > 0 &&
              orders.map((orderItem) => {
                return (
                  <OrderItem
                    key={orderItem.identifier}
                    patientName={orderItem.patientName}
                    createdAt={orderItem.createdAt}
                    items={orderItem.medications}
                    order_identifier={orderItem.identifier}
                    shipping={orderItem.shippingInfo}
                    status={orderItem.status}
                    triageInfo={orderItem.triageInfo}
                    rejectionInfo={orderItem.rejectionInfo}
                    patientToken={selectedPatientToken}
                    customerToken={customerProfile.vpharmCustomerToken}
                  />
                )
              })}
            {orders.length === 0 && (
              <>
                {showError ? (
                  <Header variant='xl' data-testid='empty-orders-error-message'>
                    There has been an error retrieving the orders for this account.
                  </Header>
                ) : (
                  <Header variant='xl' data-testid='empty-orders'>
                    No orders have been placed at the moment
                  </Header>
                )}
              </>
            )}

            {showError && (
              <TryAgainButton
                onClick={() => {
                  trackButtonClickEvent('orders_try_again_button', 'try again', 'reload orders page')
                  handleRetryButtonClick()
                }}
                data-testid='try-again-button'
                vpTheme={theme}
              >
                Try again
              </TryAgainButton>
            )}
          </InfiniteScroll>
        </ContentWrapper>
      </OrdersContainer>
    </Fragment>
  )
}

export default Orders
